<template>
  <van-cell-group>
    <van-cell
      v-for="(item, index) in list"
      :key="index"
      is-link
      :title="item.tradeNumber"
      @click="toDetail(item)"
    >
      <template #label>
        <div>{{ item.nodeName }}</div>
        <div>{{ item.tradeDate }}</div>
      </template>
    </van-cell>
  </van-cell-group>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.listZkhRetreatLog()
  },
  methods: {
    async listZkhRetreatLog() {
      const { data } = await this.$api.listZkhRetreatLog()
      console.log('listZkhRetreatLog===>', data)
      this.list = data
    },
    toDetail(row) {
      this.$router.push({ name: 'ReturnDetail', params: row })
    },
  },
}
</script>